<template>
  <BCard>
    <BRow class=" justify-between">
      <BCol class="flex items-center">
        <strong class="text-black text-bold text-xl">Data Affiliator</strong>
      </BCol>
      <BCol
        class="flex justify-end items-center"
      >
        <BButton
          id="popoverService"
          v-model="serviceName"
          class="btn-smd-flex w-50 justify-between items-center mr-1"
          type="button"
          aria-expanded="false"
          size="small"
          variant="outline-secondary"
          style="border-color: #e2e2e2 !important; height: 32px; padding-top: 5px;"
        >
          <div class="d-flex align-items-center">
            <img
              v-if="isBoostr"
              :src="iconBoostr"
              alt="Komship"
              :style="
                isBoostr
                  ? 'width: 18px; margin-left: -8px'
                  : 'width: 16px' && isAllProduct
                    ? 'display: none'
                    : 'display: flex' && isKomship
                      ? 'width: 16px; margin-left: -8px'
                      : 'width: 16px'
              "
            >
            <img
              v-if="isKomship"
              :src="iconKomship"
              alt="Komship"
              :style="
                isBoostr
                  ? 'width: 18px; margin-left: -8px'
                  : 'width: 16px' && isAllProduct
                    ? 'display: none'
                    : 'display: flex' && isKomship
                      ? 'width: 16px; margin-left: -8px'
                      : 'width: 16px'
              "
            >
            <img
              v-if="isKompack"
              :src="iconKompack"
              alt="Komship"
              :style="
                isBoostr
                  ? 'width: 18px; margin-left: -8px'
                  : 'width: 16px' && isAllProduct
                    ? 'display: none'
                    : 'display: flex' && isKomship
                      ? 'width: 16px; margin-left: -8px'
                      : 'width: 16px'
              "
            >
            <strong
              v-if="serviceName === 'komship'"
              class="capitalize text-black"
              :style="
                isAllProduct
                  ? 'display: none'
                  : 'display: flex' && isKomship
                    ? 'margin-left: 4px'
                    : ''
              "
            >
              Komship
            </strong>
            <strong
              v-if="serviceName === 'boostr'"
              class="capitalize text-black ms-2"
              :style="
                isAllProduct
                  ? 'display: none'
                  : 'display: flex' && isKomship
                    ? 'margin-left: 4px'
                    : ''
              "
            >
              Komplace
            </strong>
            <strong
              v-if="serviceName === 'kompack'"
              class="capitalize text-black ms-2"
              :style="
                isAllProduct
                  ? 'display: none'
                  : 'display: flex' && isKomship
                    ? 'margin-left: 4px'
                    : ''
              "
            >
              Kompack
            </strong>
            <strong
              class="capitalize text-black pt-[3px]"
              :style="
                isAllProduct
                  ? 'display: flex; margin-left: -8px'
                  : 'display: none'
              "
            >
              Semua
            </strong>
          </div>
        </BButton>
        <BPopover
          id="popover-filter-type"
          target="popoverService"
          triggers="focus"
          placement="bottom"
          style="padding: 0px !important"
        >
          <ul style="margin: -8px -10px -8px -10px;">
            <li>
              <BButton
                class="d-flex align-items-center h-10"
                style="padding-left: 0px; padding-right: 10px; width: 120px;"
                variant="flat-dark"
                @click="changePlatform('all')"
              >
                <strong class="capitalize text-black">Semua</strong></BButton>
            </li>
            <li
              v-for="(value, idx) in platform"
              :key="idx"
            >
              <BButton
                class="d-flex align-items-center h-10"
                style="padding-left: 0px; padding-right: 10px; width: 120px;"
                variant="flat-dark"
                @click="changePlatform(value.name)"
              >
                <img
                  :src="value.image"
                  alt="Komerce"
                  style="width:20px"
                >
                <strong
                  v-if="value.name === 'komship'"
                  class="ms-2 capitalize text-black"
                >Komship</strong>
                <strong
                  v-if="value.name === 'boostr'"
                  class="ms-2 capitalize text-black"
                >Komplace</strong>
                <strong
                  v-if="value.name === 'kompack'"
                  class="ms-2 capitalize text-black"
                >Kompack</strong>
              </BButton>
            </li>
          </ul>
        </BPopover>
        <DateRangePicker
          ref="picker"
          v-model="dateRange"
          :locale-data="locale"
          :ranges="ranges"
          :opens="'left'"
          class="withdrawal-date-range mr-1"
          style="min-width: 170px !important"
        >
          <template v-slot:input="picker">
            <div class="d-flex justify-content-between align-items-center mx-1">
              <span
                v-if="
                  YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(today)
                "
                style="color: #11263C !important; text-wrap: nowrap;"
              >
                Hari ini
              </span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last2)
                "
                style="color: #11263C !important; text-wrap: nowrap;"
              >
                2 Hari Terakhir
              </span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last7)
                "
                style="color: #11263C !important; text-wrap: nowrap;"
              >
                7 Hari Terakhir
              </span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) ===
                    YEAR_MONTH_DAY(firstDateOfMonth) &&
                    YEAR_MONTH_DAY(picker.endDate) === YEAR_MONTH_DAY(lastDateOfMonth)
                "
                style="color: #11263C !important;text-wrap: nowrap;"
              >
                Bulan ini
              </span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) ===
                    YEAR_MONTH_DAY(affiliateDate) && YEAR_MONTH_DAY(picker.endDate) ===
                      YEAR_MONTH_DAY(today)"
                style="color: #11263C !important;text-wrap: nowrap;"
              >
                Semua
              </span>

              <span
                v-else
                style="color: #11263C !important; text-wrap: nowrap;"
              >
                {{ DDMMYYYY(picker.startDate) }} - {{ DDMMYYYY(picker.endDate) }}
              </span>

              <img
                src="https://storage.googleapis.com/komerce/assets/illustration/calendar.png"
                alt="Calendar"
                class="h-5 ml-1"
              >
            </div> </template></DateRangePicker>

        <BInputGroup class="wrapper-search">
          <template #prepend>
            <BInputGroupText style="border-right: 0">
              <b-img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/search-normal-1.svg"
              />
            </BInputGroupText>
          </template>
          <BFormInput
            v-model="search"
            placeholder="Cari nama atau email afiliator"
            style="border-left: 0"
            class="pl-0"
            @input="searchData"
          />
        </BInputGroup>
      </BCol>
    </BRow>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        :items="items"
        :fields="fields"
        show-empty
        :empty-text="dataEmpty"
        responsive
        class="mt-1"
      >
        <template #cell(id)="data">
          <div style="min-width: 20px !important">
            {{ data.index + 1 }}
          </div>
        </template>
        <template #cell(affiliator_name)="data">
          <div class="d-flex min-w-[140px]">
            {{ data.item.affiliator_name }}
            <img
              v-if="data.item.is_pumkm === 1"
              src="https://storage.googleapis.com/komerce/assets/svg/Badge_PUMKM.svg"
              alt="Pendamping UMKM"
              class="ml-[4px]"
              width="20px"
            >
          </div>
          <span class="text-sm text-[#828282]">{{
            data.item.affiliator_email
          }}</span>
        </template>
        <template #cell(phone_no)="data">
          <div
            class="flex items-center"
            style="min-width: 140px !important"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg"
              alt="Komerce"
              class="mr-[5px] cursor-pointer"
              @click="handlePhone(data.item.phone_no)"
            >
            <span v-if="!isEmpty(data.item.phone_no)">{{
              data.item.phone_no
            }}</span>
            <span v-else>-</span>
          </div>
        </template>
        <template #cell(join_date)="data">
          <div style="min-width: 145px !important">
            {{ DAY_MONTH_YEAR(data.item.join_date) }}
          </div>
        </template>
        <template #cell(total_member)="data">
          <div
            class="d-flex"
            style="min-width: 100px !important"
          >
            <span
              :style="isAllProduct || isKompack ? 'display: flex' : 'display: none'"
            >{{ data.item.total_member.total }} User</span>
            <span
              :style="isKomship ? 'display: flex' : 'display: none'"
            >{{ data.item.total_member.komship }} User</span>
            <span
              :style="isBoostr ? 'display: flex' : 'display: none'"
            >{{ data.item.total_member.boostr }} User</span>
            <img
              :id="`icon-tooltip-${data.item.affiliator_id}`"
              src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
              alt="Komerce"
              variant="outline-primary"
              class="ml-[5px] cursor-pointer"
              style="width: 15px"
              :style="isAllProduct ? 'display: flex' : 'display: none'"
            >
            <b-tooltip
              :target="`icon-tooltip-${data.item.affiliator_id}`"
              triggers="hover"
              class="tooltip-inner"
              placement="right"
            >
              <div class="d-flex">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/Elipseyellow.svg"
                >
                <span class="countKomship mx-[5px]">Komship :</span>
                <span>{{ data.item.total_member.komship || 0 }}</span>
              </div>
              <div class="d-flex">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/Elipseorange.svg"
                >
                <span class="countBoostr mx-[5px]">Komplace :</span>
                <span>{{ data.item.total_member.boostr || 0 }}</span>
              </div>
              <div class="d-flex">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/Ellipsekompack.svg"
                  width="8px"
                >
                <span class="countBoostr mx-[5px]">Kompack :</span>
                <span>{{ data.item.total_member.kompack || 0 }}</span>
              </div>
            </b-tooltip>
          </div>
        </template>
        <template #cell(total_commission)="data">
          <div style="min-width: 130px !important">
            {{ IDR(data.item.total_commission) || 0 }}
          </div>
        </template>
        <template #cell(action)="data">
          <div
            class="underline font-medium cursor-pointer"
            style="color: #F95031; min-width: 50px !important;"
            @click="handleClik(data.item)"
          >
            Detail
          </div>
        </template>
      </BTable>
    </BOverlay>
    <BRow>
      <BCol
        cols="12"
        class="d-flex justify-content-between"
      >
        <div
          class="bg-light d-flex justify-content-center align-items-center p-50 rounded"
        >
          <span class="text-black mr-50"> List per halaman: </span>
          <BButton
            v-for="page in optionsPage"
            :key="page"
            class="btn-icon"
            size="sm"
            :variant="totalPerPage === page ? 'primary' : 'flat-dark'"
            @click="setPerPage(page)"
          >
            {{ page }}
          </BButton>
        </div>

        <BPagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="totalPerPage"
          first-number
          last-number
          class="pagination-primary"
        >
          <template #prev-text>
            <feather-icon
              size="18"
              icon="ChevronLeftIcon"
            />
          </template>
          <template #next-text>
            <feather-icon
              size="18"
              icon="ChevronRightIcon"
            />
          </template>
        </BPagination>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import {
  DAY_MONTH_YEAR,
  LABELMONTH,
  YEAR_MONTH,
  YEAR_MONTH_DAY,
  DDMMYYYY,
  LABELDAY,
} from '@/libs/filterDate'
import {
  firstDateOfMonth,
  affiliateDate,
  last2,
  last7,
  lastDateOfMonth,
  today,
} from '@/store/helpers'

import { IDR } from '@/libs/currency'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { affiliateAxiosIns } from '@/libs/axios'
import secureLocalStorage from '@/libs/secureLocalstorage'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { columnAffiliator, columnMemberKompack, serviceName } from '../config'

export default {
  components: { DateRangePicker },
  data() {
    return {
      items: [],
      filter: '2023-07',
      fields: columnAffiliator,
      fieldsKompack: columnMemberKompack,
      search: '',
      currentPage: 1,
      lastDateOfMonth,
      totalRows: 0,
      optionsPage: [20, 50],
      totalPerPage: 20,
      DAY_MONTH_YEAR,
      YEAR_MONTH_DAY,
      DDMMYYYY,
      affiliateDate,
      last2,
      last7,
      firstDateOfMonth,
      IDR,
      monthlabel: LABELMONTH,
      loading: false,
      serviceName: 'all',
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      dateRange: {
        startDate: firstDateOfMonth,
        endDate: lastDateOfMonth,
      },
      ranges: {
        'Hari ini': [today, today],
        '2 Hari terakhir': [last2, today],
        '7 Hari Terakhir': [last7, today],
        'Bulan ini': [firstDateOfMonth, lastDateOfMonth],
        'Semua ': [affiliateDate, today],
      },
      iconKomship:
        'https://storage.googleapis.com/komerce/assets/svg/icon-komship-outline.svg',
      iconBoostr:
        'https://storage.googleapis.com/komerce/assets/logo/Logo-Komplace-main.svg',
      iconKompack:
        'https://storage.googleapis.com/komerce/assets/svg/logo_kompack.svg',
      isKompack: false,
      isKomship: false,
      isBoostr: false,
      isAllProduct: true,
      platform: [
        {
          image:
            'https://storage.googleapis.com/komerce/assets/svg/icon-komship-outline.svg',
          name: 'komship',
        },
        {
          image:
            'https://storage.googleapis.com/komerce/assets/logo/Logo-Komplace-main.svg',
          name: 'boostr',
        },
        {
          image:
            'https://storage.googleapis.com/komerce/assets/svg/logo_kompack.svg',
          name: 'kompack',
        },
      ],
      today,
      isEmpty,
      dataEmpty: 'Tidak ada data yang ditampilkan.',
    }
  },
  computed: {
    maxDatePicker() {
      return moment().endOf('month')
    },
  },
  watch: {
    currentPage: {
      handler() {
        this.getData()
      },
    },
    filter: {
      handler() {
        this.getData()
      },
    },
    dateRange: {
      handler() {
        this.getData()
      },
    },
    serviceName: {
      handler(value) {
        if (value === 'all') {
          this.isAllProduct = true
          this.isKomship = false
          this.isBoostr = false
          this.isKompack = false
        }
        if (value === 'komship') {
          this.isAllProduct = false
          this.isKomship = true
          this.isBoostr = false
          this.isKompack = false
        }
        if (value === 'boostr') {
          this.isAllProduct = false
          this.isKomship = false
          this.isBoostr = true
          this.isKompack = false
        }
        if (value === 'kompack') {
          this.isAllProduct = false
          this.isKomship = false
          this.isBoostr = false
          this.isKompack = true
        }
      },
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      const params = {
        start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
        end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
        search: this.search,
        per_page: this.totalPerPage,
        page: this.currentPage,
        service_name: this.serviceName,
      }
      const url = '/v1/affiliator/admin/affiliator-data'
      await affiliateAxiosIns
        .get(url, { params })
        .then(res => {
          const { data, total } = res.data
          this.items = data !== null ? data.data : []
          this.totalRows = total
          this.loading = false
        })
        .catch(err => {
          this.dataEmpty = 'Tidak ada data yang ditampilkan.'
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            2000,
          )
          this.loading = false
        })
    },
    handlePhone(phoneNumber) {
      window.open(`https://wa.me/62${phoneNumber.substring(1)}`, '_blank')
    },
    handleClik(data) {
      const { affiliator_id } = data
      // eslint-disable-next-line camelcase
      this.$router.push({ path: `/dashboard-affiliate/${affiliator_id}` })
      secureLocalStorage.set('memberAffiliate', JSON.stringify(data))
    },
    setPerPage(page) {
      this.totalPerPage = page
      this.getData()
    },
    searchData: _.debounce(function search() {
      this.getData()
    }, 1000),
    changePlatform(value) {
      if (value === 'all') {
        this.serviceName = 'all'
        this.isAllProduct = true
      }
      if (value === 'komship') {
        this.serviceName = 'komship'
        this.isKomship = true
        this.isAllProduct = false
      }
      if (value === 'boostr') {
        this.serviceName = 'boostr'
        this.isBoostr = true
        this.isAllProduct = false
      }
      if (value === 'kompack') {
        this.serviceName = 'kompack'
        this.isKompack = true
        this.isAllProduct = false
      }
      this.getData()
      this.$root.$emit('bv::hide::popover', 'popover-filter-type')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../Dashboard.scss';
</style>
